import DemandResponseIcon from "@mui/icons-material/TrendingDown";
import { DemandResponseCreate, DemandResponseEdit } from "./edit/Edit";
import { DemandResponseShow } from "./show";

export const demandResponseResource = {
  icon: DemandResponseIcon,
  edit: DemandResponseEdit,
  show: DemandResponseShow,
  create: DemandResponseCreate,
  options: { label: "Effacements" },
};

export { DemandResponseIcon };
