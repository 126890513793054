import { Paper } from "@mui/material";
import { useToggleFilter } from "../../../filters/useToggleFilter";
import { useIncidentsState } from "../../../providers/data-hooks/useIncidentsState";
import { CountEnumChart } from "./CountEnumChart";

export const IncidentsStatus = ({
  filter,
}: {
  filter?: { projectId: string } | { dealId: string };
}) => {
  const counts = useIncidentsState("status", filter);
  const [filteredValue, toggleFilter] = useToggleFilter("status");

  return (
    <Paper elevation={1}>
      <CountEnumChart
        title="Statut des incidents"
        onClick={(point) => toggleFilter(point.name)}
        data={
          counts &&
          Object.entries(counts).map(([status, count]) => ({
            name: status,
            y: count,
            sliced: filteredValue === status,
          }))
        }
      />
    </Paper>
  );
};
