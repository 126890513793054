import DeviceIcon from "@mui/icons-material/SpeakerPhone";
import { EditDevice } from "./Edit";
import { DeviceList } from "./List";
import { ShowDevice } from "./show";

export const deviceResource = {
  list: DeviceList,
  show: ShowDevice,
  edit: EditDevice,
  icon: DeviceIcon,
  recordRepresentation: "IMEI",
};

export { DeviceIcon };
