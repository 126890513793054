import TableChartIcon from "@mui/icons-material/TableChart";
import { Checkbox, FormControlLabel, Popover } from "@mui/material";
import { cloneElement, useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Button, useStore } from "react-admin";

export const SelectColumnsButton = ({
  preference,
  columns,
  children,
  ...rest
}: SelectColumnsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  return (
    <>
      <Button
        color="primary"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        label="Choose columns"
        {...rest}
      >
        {children || <TableChartIcon />}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SelectColumnsMenu preference={preference} columns={columns} />
      </Popover>
    </>
  );
};

export interface SelectColumnsButtonProps {
  preference: string;
  columns: Record<string, ReactElement>;
  children?: ReactElement;
}

export const SelectColumnsMenu = ({
  preference,
  columns,
}: SelectColumnsMenuProps) => {
  const [columnsPref, setColumnsPref] = useStore<string[]>(
    preference,
    Object.keys(columns)
  );

  const addColumn = (event: any): void => {
    setColumnsPref(
      Object.keys(columns).filter(
        (name) => name === event.target.name || columnsPref.includes(name)
      )
    );
  };

  const removeColumn = (event: any): void => {
    // always leave at least one column
    if (columnsPref.length > 1) {
      setColumnsPref(columnsPref.filter((name) => name !== event.target.name));
    }
  };

  return (
    <div style={{ margin: `2px 4px` }}>
      {Object.keys(columns).map((name) => (
        <div key={name}>
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                checked={columnsPref.includes(name)}
                onClick={columnsPref.includes(name) ? removeColumn : addColumn}
              />
            }
            label={(columns[name].props as any).label}
          />
        </div>
      ))}
    </div>
  );
};

export interface SelectColumnsMenuProps {
  preference: string;
  columns: Record<string, ReactElement>;
  className?: string;
}

export const useSelectedColumns = ({
  preferences,
  columns,
  omit,
  defaultColumns,
}: UseSelectedColumnsArg): ReactElement[] => {
  const [columnsFromPrefs, setColumnsFromPref] =
    useStore<string[]>(preferences);

  useEffect(() => {
    if (omit && !columnsFromPrefs) {
      setColumnsFromPref(
        Object.keys(columns).filter((name) => !omit.includes(name))
      );
    }
  }, [JSON.stringify(omit), columns, columnsFromPrefs, setColumnsFromPref]); // eslint-disable-line

  useEffect(() => {
    if (!columnsFromPrefs) {
      setColumnsFromPref(defaultColumns || Object.keys(columns));
    }
  }, [defaultColumns, columns, setColumnsFromPref, columnsFromPrefs]);

  const columnList = columnsFromPrefs
    ? columnsFromPrefs.filter((name: any) => !!columns[name])
    : [];
  return columnList.map((name: any) =>
    cloneElement(columns[name], {
      key: columns[name].key || name,
      ...columns[name].props,
    })
  );
};

export type ColumnList = Record<string, ReactElement>;

export interface UseSelectedColumnsArg {
  preferences: string;
  columns: ColumnList;
  omit?: string[];
  defaultColumns?: string[];
}
