import ProjectIcon from "@mui/icons-material/FactCheck";
import { ProjectCreate, ProjectEdit } from "./Edit";
import { ProjectList } from "./List";
import { ProjectShow } from "./show";

export const projectResource = {
  icon: ProjectIcon,
  list: ProjectList,
  show: ProjectShow,
  edit: ProjectEdit,
  create: ProjectCreate,
  recordRepresentation: "name",
};

export { ProjectIcon };
