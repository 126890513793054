import { MenuItem, Select } from "@mui/material";
import { createEnumParam, useQueryParam, withDefault } from "use-query-params";
import { YearlyNormalization } from "../../providers/data";

export const useYearlyNormalized = () => {
  return useQueryParam(
    "yearlyNormalized",
    withDefault(
      createEnumParam<YearlyNormalization>([
        YearlyNormalization.NO,
        YearlyNormalization.ELAX,
        YearlyNormalization.ADEME,
      ]),
      YearlyNormalization.ELAX
    )
  );
};

export const YearlyNormalizationSelect = () => {
  const [yearlyNormalized, setYearlyNormalized] = useYearlyNormalized();

  return (
    <Select
      value={yearlyNormalized}
      onChange={(event) =>
        setYearlyNormalized(event.target.value as YearlyNormalization)
      }
      size="small"
      displayEmpty
      sx={{ marginLeft: 1 }}
    >
      <MenuItem value={YearlyNormalization.NO}>Sans saisonnalité</MenuItem>
      <MenuItem value={YearlyNormalization.ELAX}>Saisonnalité Elax</MenuItem>
      <MenuItem value={YearlyNormalization.ADEME}>Saisonnalité ADEME</MenuItem>
    </Select>
  );
};
