import CloseIcon from "@mui/icons-material/Close";
import ExportIcon from "@mui/icons-material/GetApp";
import { Alert, Button, CircularProgress, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useBulkActionFilter } from "./useBulkActionFilter";

export { ExportIcon };

export const useExport = (handleExport: (filter: any) => Promise<string>) => {
  const filter = useBulkActionFilter();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMutation(
    ["export"],
    () => {
      enqueueSnackbar("Asking for export...", {
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        autoHideDuration: null,
        action: (
          <>
            <CircularProgress size={15} />
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar()}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ),
      });
      return handleExport(filter);
    },
    {
      onSuccess: (slackUrl) => {
        closeSnackbar();
        enqueueSnackbar("Export will be ready soon, check in #bo-exports", {
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          autoHideDuration: null,
          action: (
            <>
              <Button
                size="small"
                onClick={() => {
                  window.open(slackUrl);
                  closeSnackbar();
                }}
              >
                OPEN
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar()}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          ),
        });
      },
      onError: (error: any) => {
        enqueueSnackbar(
          <Alert severity="error">{`Failed to export data: ${error.message}`}</Alert>
        );
      },
    }
  );
};
