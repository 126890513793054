import GroupIcon from "@mui/icons-material/JoinFull";
import { GroupCreate, GroupEdit } from "./Edit";
import { GroupList } from "./List";
import { GroupShow } from "./Show";

export const groupResource = {
  list: GroupList,
  show: GroupShow,
  edit: GroupEdit,
  create: GroupCreate,
  icon: GroupIcon,
  recordRepresentation: "groupName",
};
export { GroupIcon };
