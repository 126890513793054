import PostIcon from "@mui/icons-material/Description";
import { PostCreate, PostEdit } from "./Edit";
import { PostList } from "./List";
import { PostShow } from "./Show";

export const postResource = {
  icon: PostIcon,
  list: PostList,
  edit: PostEdit,
  show: PostShow,
  create: PostCreate,
  options: { label: "Posts" },
};

export { PostIcon };
