import { useMaintenancesState } from "../../../providers/data-hooks/useMaintenancesState";
import { CountEnumChart } from "./CountEnumChart";

export const MaintenanceStatus = ({
  filter,
}: {
  filter?: { projectId: string } | { dealId: string };
}) => {
  const data = useMaintenancesState(filter);

  return (
    <CountEnumChart
      title="Statut des SAV"
      data={data?.map(({ status, count }) => ({
        name: status,
        y: count,
      }))}
    />
  );
};
