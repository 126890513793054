import { Divider, Grid } from "@mui/material";
import { useRecordContext } from "react-admin";
import { usePrint } from "../../../../actions/PDFReport";
import { YearlyNormalizationSelect } from "../../../../layout/dashboard/YearlyNormalizationSelect";
import { ConsumptionGraph } from "../../../../layout/dashboard/widgets/Consumption";
import { ConsumptionBar } from "../../../../layout/dashboard/widgets/ConsumptionBar";
import { ContractType } from "../../../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../../../layout/dashboard/widgets/HeaterState";
import { SavingsState } from "../../../../layout/dashboard/widgets/SavingsState";
import type { Organization } from "../../types";

export const ClientDashboard = () => {
  const client = useRecordContext<Organization>();
  const [print] = usePrint();

  return (
    <Grid
      container
      spacing={2}
      id="report"
      sx={
        print
          ? {
              width: "21cm",
              padding: 3,
            }
          : {}
      }
    >
      <Grid item xs={12}>
        <YearlyNormalizationSelect />
      </Grid>
      <ConsumptionGraph filter={client && { organizationId: client.id }} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ConsumptionBar filter={client && { organizationId: client.id }} />
      </Grid>
      <HealthRisk filter={client && { organizationId: client.id }} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <ContractType filter={client && { organizationId: client.id }} />
      </Grid>
      <Grid item xs={6}>
        <SavingsState filter={client && { organizationId: client.id }} />
      </Grid>
      <Grid item xs={6}>
        <HeatersState filter={client && { organizationId: client.id }} />
      </Grid>
      <Grid item xs={6}>
        <DevicesState filter={client && { organizationId: client.id }} />
      </Grid>
    </Grid>
  );
};
