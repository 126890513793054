import {
  Create,
  Edit,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import type { RaRecord } from "react-admin";
import { prepareFiles } from "../../providers/file-handler";

const EditionForm = () => {
  return (
    <SimpleForm>
      <TextInput
        source="version"
        helperText="Firmware version following X.Y.Z (X = major, Y = minor, Z = patch). Example: '2.3.0'."
        required={true}
        fullWidth
      />
      <FileInput
        source="file"
        multiple={false}
        maxSize={320000}
        helperText="Maximum file size is 320ko"
      >
        <TextField source="title" />
      </FileInput>
      <TextInput
        source="releaseNote"
        label="Release Note"
        fullWidth
        multiline
        minRows={4}
      />
    </SimpleForm>
  );
};
export const FirmwareEdit = () => (
  <Edit transform={prepareFiles<Firmware>(["file"])}>
    <EditionForm />
  </Edit>
);

export const FirmwareCreate = () => (
  <Create transform={prepareFiles(["file"])}>
    <EditionForm />
  </Create>
);

interface Firmware extends RaRecord {
  id: string;
  version: string;
  file: {
    rawFile: File;
    src: string;
    title: string;
  };
}
