import FirmwareIcon from "@mui/icons-material/Terminal";
import { FirmwareCreate, FirmwareEdit } from "./Edit";
import { FirmwareList } from "./List";

export { FirmwareIcon };

export const firmwareResource = {
  list: FirmwareList,
  edit: FirmwareEdit,
  create: FirmwareCreate,
  icon: FirmwareIcon,
  recordRepresentation: "version",
};
