import { Grid, Paper } from "@mui/material";
import { map, orderBy } from "lodash";
import { useRedirect } from "react-admin";
import { useQuery } from "react-query";
import { dataProvider } from "../../providers/data";
import { DeviceStatus } from "../../resources/devices/types";
import { ContractType } from "./widgets/ContractType";
import { CountEnumChart } from "./widgets/CountEnumChart";
import { DevicesState } from "./widgets/DevicesState";
import { HealthRisk } from "./widgets/HealthRisk";
import { HeatersState } from "./widgets/HeaterState";

export const DevicesDashboard: React.FC<{ filter: any }> = ({ filter }) => {
  const { data } = useQuery({
    queryFn: () => dataProvider.deviceAnalytics(filter),
    queryKey: ["device-analytics"],
  });
  const redirect = useRedirect();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ContractType filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <HeatersState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <DevicesState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={6}>
        <HealthRisk filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3}>
          <CountEnumChart
            title="Device statuses"
            onClick={(point) => {
              redirect(() => `devices?filter={"status": "${point.name}"}`);
            }}
            data={
              data && [
                {
                  name: DeviceStatus.PRODUCTION,
                  y: data.data.statuses[DeviceStatus.PRODUCTION],
                },
                {
                  name: DeviceStatus.STOCK,
                  y: data.data.statuses[DeviceStatus.STOCK],
                },
                {
                  name: DeviceStatus.TEST,
                  y: data.data.statuses[DeviceStatus.TEST],
                },
                {
                  name: DeviceStatus.USED,
                  y: data.data.statuses[DeviceStatus.USED],
                },
                {
                  name: DeviceStatus.OUT_OF_ORDER,
                  y: data.data.statuses[DeviceStatus.OUT_OF_ORDER],
                },
              ]
            }
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3}>
          <CountEnumChart
            title="Firmware versions"
            onClick={(point) => {
              redirect(
                () => `devices?filter={"firmwareVersion": "${point.name}"}`
              );
            }}
            data={
              data &&
              orderBy(
                map(data.data.firmwareVersions, (count, status) => ({
                  name: status,
                  y: count,
                })),
                "name"
              )
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
