import EnergyIcon from "@mui/icons-material/Bolt";
import DataFrameIcon from "@mui/icons-material/CellTower";
import OnTimerIcon from "@mui/icons-material/PowerSettingsNew";
import TemperatureIcon from "@mui/icons-material/Thermostat";
import TimestampIcon from "@mui/icons-material/Timer";
import OnTriggersIcon from "@mui/icons-material/ToggleOn";
import dayjs from "dayjs";
import type { DataFrame } from "../../../../../frames/data/type";
import { IconInfo } from "./shared";
import type { FrameSpecs } from "./shared";

export const dataFrameSpecs: FrameSpecs<DataFrame> = {
  icon: <DataFrameIcon sx={{ color: "#2196f3" }} />,
  data: [
    {
      key: "Temp1Avg" as const,
      label: (frame) => (
        <IconInfo
          first
          title={`T1 average = ${frame.temperature1}°C - last T1 = ${frame.last_temperature1}°C - max T1 = ${frame.max_temperature1}°C`}
          icon={<TemperatureIcon color="error" />}
        >
          {`${frame.temperature1.toFixed(
            1
          )}°C (last ${frame.last_temperature1.toFixed(1)}  / max ${
            frame.max_temperature1?.toFixed(1) ?? "N/A"
          })`}
        </IconInfo>
      ),
    },
    {
      key: "Temp2Avg" as const,
      label: (frame) => (
        <IconInfo
          title={`T2 average = ${frame.temperature2}°C - last T2 = ${frame.last_temperature2}°C - max T2 = ${frame.max_temperature2}°C`}
          icon={<TemperatureIcon color="warning" />}
        >
          {`${frame.temperature2.toFixed(
            1
          )}°C (last ${frame.last_temperature2.toFixed(1)} / max ${
            frame.max_temperature2?.toFixed(1) ?? "N/A"
          })`}
        </IconInfo>
      ),
    },
    {
      key: "Energy" as const,
      label: (frame) => (
        <IconInfo icon={<EnergyIcon color="info" />}>
          {frame.energy !== undefined
            ? `${frame.energy}Wh (${(frame.max_intensity! / 100).toFixed(
                2
              )}A / ${(frame.voltage! / 100).toFixed(2)}V)`
            : null}
        </IconInfo>
      ),
    },
    {
      key: "Energy (Sync)" as const,
      label: (frame) => (
        <IconInfo icon={<EnergyIcon color="info" />}>
          {frame.synchronised_energy !== undefined
            ? `${frame.synchronised_energy}Wh`
            : null}
        </IconInfo>
      ),
    },
    {
      key: "OnTimer" as const,
      label: (frame) => (
        <IconInfo
          title={`OnTimer = ${frame.on_timer}s`}
          icon={<OnTimerIcon color="action" />}
        >
          {`${dayjs.duration(-frame.on_timer, "seconds").humanize(true)}`}
        </IconInfo>
      ),
    },
    {
      key: "OnTriggers" as const,
      label: (frame) => (
        <IconInfo
          title={`OnTriggers=${frame.on_triggers}`}
          icon={<OnTriggersIcon color="action" />}
        >
          {`${frame.on_triggers}`}
        </IconInfo>
      ),
    },
    {
      key: "Timestamp Synchronised Energy" as const,
      label: (frame) => (
        <IconInfo
          title={`Synchronized timestamp=${frame.synchronised_ts}`}
          icon={<TimestampIcon color="action" />}
        >
          {dayjs(frame.synchronised_ts).format("YYYY-MM-DD HH:mm:ss")}
        </IconInfo>
      ),
    },
    {
      key: "Timestamp Device" as const,
      label: (frame) => (
        <IconInfo
          title={`Device timestamp=${frame.device_ts}`}
          icon={<TimestampIcon color="action" />}
        >
          {dayjs(frame.device_ts).format("YYYY-MM-DD HH:mm:ss")}
        </IconInfo>
      ),
    },
  ],
};
