import { useRecordContext } from "react-admin";
import { useWatch } from "react-hook-form";
import { useDeviceEndUser } from "../../../providers/data-hooks/useDeviceEndUser";
import { PersonaAlert } from "../../devices/show/tabs/pilot/Persona";
import type { Device } from "../../devices/types";
import { Mode } from "../types";
import { FlexParameters, FlexParamsToString } from "./flex";
import { ScheduleParameters } from "./schedule";
import { SetPointParamsToString, StaticModeParameters } from "./set-point";
import { SmartParameters, SmartParamsToString } from "./smart";
import {
  SmartEnergyParameters,
  SmartEnergyParamsToString,
} from "./smart-energy";
import { SmartHPHCParameters, SmartHPHCParamsToString } from "./smart-hphc";

const parametersToString: Record<
  Mode,
  (params: any, isHCHP: boolean, majorHWVersion: 1 | 2 | 3) => string
> = {
  [Mode.SET_POINT]: SetPointParamsToString,
  [Mode.OBSERVATION]: SetPointParamsToString,
  [Mode.PRE_OBSERVATION]: SetPointParamsToString,
  [Mode.SMART]: (config, isHCHP) =>
    isHCHP ? SmartHPHCParamsToString(config) : SmartParamsToString(config),
  [Mode.SMART_FLEX]: FlexParamsToString,
  [Mode.SMART_FLEX_v2]: FlexParamsToString,
  [Mode.SMART_ENERGY]: (config) => SmartEnergyParamsToString(config),
  [Mode.SCHEDULE]: () => "",
  [Mode.EDF_TEMPO]: SetPointParamsToString,
};

export const Parameters: React.FC<{
  device?: Device;
  isHPHC: boolean;
  source: string;
}> = ({ device, isHPHC, source }) => {
  const mode = useWatch({
    name: source.includes(".") ? `${source.split(".")[0]!}.mode` : "mode",
  }) as Mode;

  const { endUser } = useDeviceEndUser(device);

  if (!mode) {
    return <></>;
  }

  if (
    mode === Mode.SET_POINT ||
    mode === Mode.OBSERVATION ||
    mode === Mode.PRE_OBSERVATION ||
    mode === Mode.EDF_TEMPO
  ) {
    return (
      <StaticModeParameters
        majorHWVersion={device?.majorHWVersion}
        source={source}
      />
    );
  }

  if (mode === Mode.SCHEDULE) {
    return (
      <ScheduleParameters
        majorHWVersion={device?.majorHWVersion}
        source={source}
      />
    );
  }

  if (mode === Mode.SMART) {
    if (endUser?.deviceConfigPersona && device) {
      return <PersonaAlert device={device} />;
    }
    if (isHPHC) {
      return <SmartHPHCParameters source={source} />;
    } else {
      return <SmartParameters source={source} />;
    }
  }

  if (mode === Mode.SMART_FLEX || mode === Mode.SMART_FLEX_v2) {
    return <FlexParameters source={source} />;
  }

  if (mode === Mode.SMART_ENERGY) {
    if (endUser?.deviceConfigPersona && device) {
      return <PersonaAlert device={device} />;
    }
    return <SmartEnergyParameters source={source} />;
  }

  exhaustivityCheck(mode);

  return null;
};

const exhaustivityCheck = (mode: string) => {
  throw new Error("Not implemented mode: " + mode);
};

export const ParametersField: React.FC<{
  label?: string;
  isHCHP: boolean;
  majorHWVersion: 1 | 2 | 3;
}> = ({ isHCHP, majorHWVersion }) => {
  const record = useRecordContext();

  if (!record?.mode) {
    return <></>;
  }

  return (
    <>
      {parametersToString[record.mode as Mode](
        record.parameters || {},
        isHCHP,
        majorHWVersion
      )}
    </>
  );
};
