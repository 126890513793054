import { Grid } from "@mui/material";
import { IncidentsStatus } from "./widgets/IncidentsStatus";
import { IncidentsType } from "./widgets/IncidentsType";
import { InterventionsGraph } from "./widgets/Interventions";
import { InterventionsFlow } from "./widgets/InterventionsFlow";
import { MaintenanceStatus } from "./widgets/MaintenanceStatus";

export const InterventionsDashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InterventionsGraph />
      </Grid>
      <Grid item xs={12} md={12}>
        <InterventionsFlow />
      </Grid>
      <Grid item xs={12} md={6}>
        <IncidentsStatus />
      </Grid>
      <Grid item xs={12} md={6}>
        <IncidentsType />
      </Grid>
      <Grid item xs={12} md={6}>
        <MaintenanceStatus />
      </Grid>
    </Grid>
  );
};
