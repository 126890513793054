import ElectricityPriceIcon from "@mui/icons-material/Euro";
import { ElectricityPriceList } from "./List";

export const electricityPriceResource = {
  list: ElectricityPriceList,
  icon: ElectricityPriceIcon,
  options: {
    label: "Electricy Prices",
  },
};

export { ElectricityPriceIcon };
