import PrintIcon from "@mui/icons-material/Print";
import dayjs from "dayjs";
import { useRecordContext } from "react-admin";
import { useReactToPrint } from "react-to-print";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { ActionMenuItem } from "../layout/actions-menu";

export const ExportPDFAction: React.FC = () => {
  const record = useRecordContext();
  const [, setPrint] = usePrint();
  const handlePrint = useReactToPrint({
    documentTitle: `${record.name} ${dayjs().format("MM-YYYY")}`,
    content: () => document.getElementById("report"),
    onBeforeGetContent: async () => {
      setPrint(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    },
    onAfterPrint: () => setPrint(false),
  });

  return (
    <ActionMenuItem
      onClick={handlePrint}
      label="Impression PDF"
      icon={<PrintIcon />}
    />
  );
};

export const usePrint = () =>
  useQueryParam("print", withDefault(BooleanParam, false));
