import { Grid } from "@mui/material";
import { NoWheelNumberInput } from "../../../fields/NumberInput";
import { integer } from "../../../validations/integer";

export const FlexParameters: React.FC<{ source: string }> = ({ source }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NoWheelNumberInput
          min={1}
          max={99}
          label="History Prediction Weeks"
          defaultValue={defaultValues.history_prediction_weeks}
          source={`${source}.history_prediction_weeks`}
          fullWidth
          validate={integer}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Temp Base [1°C]"
          defaultValue={defaultValues.temp_base}
          source={`${source}.temp_base`}
          fullWidth
          validate={integer}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          min={0}
          max={99}
          label="Temp Up [1°C]"
          defaultValue={defaultValues.temp_up}
          source={`${source}.temp_up`}
          fullWidth
          validate={integer}
        />
      </Grid>
    </Grid>
  );
};

export const FlexParamsToString = ({
  temp_up,
  temp_base,
  history_prediction_weeks,
}: any) =>
  `${temp_base || defaultValues.temp_base}°C/${
    temp_up || defaultValues.temp_up
  }°C ${
    history_prediction_weeks !== defaultValues.history_prediction_weeks
      ? defaultValues.history_prediction_weeks ?? ""
      : ""
  }`;

const defaultValues = {
  history_prediction_weeks: 12,
  temp_base: 43,
  temp_up: 47,
};
