import { Grid } from "@mui/material";
import {
  Create,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { prepareFiles } from "../../providers/file-handler";
import { PostBodyInput } from "./inputs/PostBodyInput";
import type { Post } from "./type";

export const PostEditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextInput source="title" fullWidth />
        </Grid>
        <Grid item sm={12}>
          <TextInput source="subtitle" fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageInput
            source="thumbnail"
            label="Thumbnail"
            maxSize={100000}
            helperText="Max 100kb. Prefer svg or transparent png"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageInput
            source="image"
            label="Image"
            maxSize={500000}
            helperText="Max 500kb"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
        <Grid item sm={12}>
          <DateInput source="publishedAt" fullWidth />
        </Grid>
        <Grid item sm={12}>
          <SelectInput
            source="category"
            choices={["USE_CASE"].map((category) => ({
              id: category,
              name: category,
            }))}
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <PostBodyInput source="body" label={false} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const PostEdit = () => (
  <Edit
    redirect="show"
    transform={prepareFiles<Post>(["thumbnail", "image"])}
    mutationMode="pessimistic"
  >
    <PostEditionForm />
  </Edit>
);

export const PostCreate = () => (
  <Create
    redirect="show"
    transform={prepareFiles<Post>(["thumbnail", "image"])}
  >
    <PostEditionForm />
  </Create>
);
