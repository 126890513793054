import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { granularities, useGranularity } from "./useGranularity";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface SavingsAverageGraphProps {
  filter: any;
}

export const SavingsAverageGraph: React.FC<SavingsAverageGraphProps> = ({
  filter,
}) => {
  const [granularity, setGranularity] = useGranularity();

  const { data } = useQuery({
    queryFn: () =>
      dataProvider.getDevicesSavingsStats(
        filter,
        granularity.key,
        ["euros"],
        "avg"
      ),
    queryKey: [
      `devices/savings/average`,
      filter,
      granularity.key,
      "euros",
      "avg",
    ],
    enabled: !!filter,
  });

  const chartOptions: Highcharts.Options = {
    title: {
      text: "Moyenne des économies générées " + granularity.label,
    },
    plotOptions: {
      spline: {
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 5,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: "€",
        },
      },
    ],
  };

  return (
    <Paper elevation={1} sx={{ position: "relative" }}>
      <FormControl
        sx={{ position: "absolute", right: 40, top: 5, zIndex: 10 }}
        size="small"
        className="no-print"
      >
        <InputLabel>Granularité</InputLabel>
        <Select
          label="Granularité"
          onChange={(event) => {
            setGranularity(
              granularities.find(
                (g) => g.value === (event.target.value as string)
              )!
            );
          }}
          value={granularity.value}
        >
          {granularities.map((g) => (
            <MenuItem key={g.value} value={g.value}>
              {g.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Chart
        isLoading={!data}
        options={{
          ...chartOptions,
          subtitle: {
            text:
              data &&
              `Based on v2 only - Last Update: ${dayjs().to(
                data.lastUpdateDate
              )}`,
          },
          xAxis: {
            categories: data?.data.map((d) => d.key),
            crosshair: true,
          },
          series: data && [
            {
              name: "€",
              type: "spline",
              color: "#2196f3",
              tooltip: {
                headerFormat: "<b>{point.key}</b>: ",
                valueDecimals: 2,
                pointFormat: `{point.y}€ ({point.custom.deviceCount} devices)`,
              },
              data: data.data.map(
                (point) =>
                  ({
                    y: point.savings_euros_avg,
                    custom: {
                      deviceCount: point.device_count,
                    },
                  } as any)
              ),
            },
          ],
        }}
      />
    </Paper>
  );
};
