import HousingIcon from "@mui/icons-material/DoorFront";
import { HousingCreate, HousingEdit } from "./edit/Edit";
import { HousingList } from "./list/List";
import { HousingShow } from "./show/Show";

export const housingResource = {
  icon: HousingIcon,
  list: HousingList,
  edit: HousingEdit,
  create: HousingCreate,
  show: HousingShow,
  recordRepresentation: "unitId",
};

export { HousingIcon };
