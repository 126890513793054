import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useState } from "react";
import { useQuery } from "react-query";
import type { AsyncReturnType } from "type-fest";
import { YearlyNormalization, dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";
import { Metric } from "./Metric";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface ConsumptionGraphProps {
  filter: any;
  v2?: boolean;
}

export const ConsumptionGraph: React.FC<ConsumptionGraphProps> = ({
  v2,
  filter,
}) => {
  const [period, setPeriod] = useState<{ start: string; end: string }>();
  const [yearlyNormalized] = useYearlyNormalized();

  const adaptedFilters = {
    ...filter,
    period,
    observation: true,
  };

  const { data, isFetching } = useQuery({
    queryFn: () =>
      v2
        ? dataProvider.getDevicesConsumptionV2(adaptedFilters)
        : dataProvider.getDevicesConsumption(adaptedFilters, yearlyNormalized),
    queryKey: [
      v2 ? `devices/consumption-v2` : `devices/consumption`,
      adaptedFilters,
      v2 ? undefined : yearlyNormalized,
    ],
    enabled: !!filter,
    staleTime: 60 * 1000,
  });

  return (
    <>
      <Grid item xs={12} md={8}>
        <Paper elevation={1} sx={{ position: "relative" }}>
          <Chart
            description={
              <>
                <Divider sx={{ color: "#2196f3" }}>
                  Consommation mesurée
                </Divider>
                <Typography>
                  Il s'agit de la consommation mesurée par l'ensemble des
                  boîtiers (v2) à partir de leur date d'installation
                </Typography>
                <Divider sx={{ color: "#607d8b", marginTop: 2 }}>
                  Estimations de consommation
                </Divider>
                {v2 ? null : (
                  <Typography>
                    Il s'agit d'une valeur calculée à partir des données
                    d'observations. Ce calcul prend en compte des absences de
                    données. L'annulation des effets saisonniers n'est pas
                    appliquée.
                  </Typography>
                )}
              </>
            }
            isLoading={!data}
            options={{
              ...chartOptions,
              xAxis: {
                ...chartOptions.xAxis,
                events: {
                  setExtremes: function (e) {
                    if (
                      typeof e.min == "undefined" &&
                      typeof e.max == "undefined"
                    ) {
                      setPeriod(undefined);
                    } else {
                      setPeriod({
                        start: dayjs(e.min).format("YYYY-MM-DD"),
                        end: dayjs(e.max).format("YYYY-MM-DD"),
                      });
                    }
                  },
                },
              },
              subtitle: {
                text: data && `Last Update: ${dayjs().to(data.lastUpdateDate)}`,
              },
              series: data && [
                {
                  name:
                    "Estimation de consommation sans boitier Elax" +
                    (!v2 && yearlyNormalized !== YearlyNormalization.NO
                      ? " (compensation saisonnière)"
                      : ""),
                  type: "spline",
                  color: "#607d8b",
                  data: data.data.consumptionProgression.map((point) => ({
                    x: point.timestamp,
                    y: point.estimatedNonPilotedConsumptionInKWh,
                    custom: {
                      deviceCount: point.pilotedDeviceCount,
                    },
                  })),
                  tooltip: {
                    headerFormat: "<b>{point.key}</b>",
                    xDateFormat: "%A %d %B",
                    valueDecimals: 1,
                    pointFormat: `<br />{point.y}kWh - {point.custom.deviceCount} chauffe-eau</b><br />
              {point.custom.interventions}</b>
              `,
                  },
                },
                {
                  name: "Consommation mesurée",
                  type: "spline",
                  color: "#2196f3",
                  data: data.data.consumptionProgression.map((point) => ({
                    x: point.timestamp,
                    y: point.measuredConsumptionInKWh,
                    custom: {
                      deviceCount: point.deviceCount,
                    },
                  })),
                  tooltip: {
                    headerFormat: "<b>{point.key}</b>",
                    xDateFormat: "%A %d %B",
                    valueDecimals: 1,
                    pointFormat: `<br />{point.y}kWh - {point.custom.deviceCount} chauffe-eaux</b><br />
              {point.custom.interventions}</b>
              `,
                  },
                },
                {
                  name: "Économies",
                  type: "arearange",
                  data: data.data.consumptionProgression.map((point) => [
                    point.timestamp,
                    point.measuredConsumptionInKWh,
                    point.estimatedNonPilotedConsumptionInKWh,
                  ]),
                  marker: {
                    enabled: false,
                  },
                  showInLegend: false,
                  lineWidth: 0,
                  color: "#607d8b",
                  negativeColor: "#f44336",
                  fillOpacity: 0.3,
                  enableMouseTracking: false,
                },
              ],
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={1}
          sx={{
            flexGrow: 1,
            minHeight: "100%",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            padding={1}
          >
            Consommation moyenne
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexGrow: 1,
              height: "100%",
            }}
          >
            {v2 ? (
              <>
                <Metric
                  title="Mesurée"
                  value={data?.data?.averagePilotConsumption}
                  unit="kWh/chauffe-eau/jour"
                  isLoading={isFetching}
                />
                <Metric
                  title="Estimée sans pilotage"
                  value={
                    (
                      data?.data as AsyncReturnType<
                        typeof dataProvider.getDevicesConsumptionV2
                      >["data"]
                    )?.averageEstimatedNonPilotedConsumption
                  }
                  unit="kWh/chauffe-eau/jour"
                  isLoading={isFetching}
                />
              </>
            ) : (
              <>
                <Metric
                  title="Observation"
                  value={
                    (
                      data?.data as AsyncReturnType<
                        typeof dataProvider.getDevicesConsumption
                      >["data"]
                    )?.averageObservationConsumption
                  }
                  unit="kWh/chauffe-eau/jour"
                  isLoading={isFetching}
                />
                <Metric
                  title="Pilotage"
                  value={data?.data?.averagePilotConsumption}
                  reference={
                    (
                      data?.data as AsyncReturnType<
                        typeof dataProvider.getDevicesConsumption
                      >["data"]
                    )?.averageObservationConsumption
                  }
                  unit="kWh/chauffe-eau/jour"
                  isLoading={isFetching}
                />
              </>
            )}
          </Box>
        </Paper>
      </Grid>
    </>
  );
};

const chartOptions: Highcharts.Options = {
  chart: {
    type: "line",
    zooming: {
      type: "x",
    },
  },
  title: {
    text: "Consommation quotidienne des chauffe-eau équipés",
  },
  plotOptions: {
    spline: {
      lineWidth: 3,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      marker: {
        enabled: false,
      },
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    crosshair: true,
    type: "datetime",
    tickPixelInterval: 150,
  },
  yAxis: {
    title: {
      text: "",
    },
    labels: {
      formatter: function () {
        return this.value + "kWh";
      },
    },
  },
};
