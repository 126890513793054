import { Grid } from "@mui/material";
import { DeviceStatus } from "../../resources/devices/types";
import { AnnualSavings } from "./widgets/AnnualSavings";
import { ConsumptionGraph } from "./widgets/Consumption";
import { ConsumptionBar } from "./widgets/ConsumptionBar";
import { ConsumptionProgressionGraph } from "./widgets/ConsumptionProgression";
import { MonthlySavingsGraph } from "./widgets/MonthlySavings";
import { SavingsProgressionGraph } from "./widgets/SavingsProgression";
import { SavingsState } from "./widgets/SavingsState";

export const SavingsDashboard: React.FC<{ filter: any }> = ({ filter }) => {
  return (
    <Grid container spacing={2}>
      <ConsumptionGraph filter={filter ?? {}} />
      <Grid item xs={12} md={6}>
        <AnnualSavings
          filter={{
            status: DeviceStatus.USED,
            ...filter,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConsumptionBar filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SavingsState filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <MonthlySavingsGraph filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <SavingsProgressionGraph filter={filter ?? {}} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ConsumptionProgressionGraph filter={filter ?? {}} />
      </Grid>
    </Grid>
  );
};
