import MaintenanceProviderIcon from "@mui/icons-material/Engineering";
import { MaintenanceProviderCreate, MaintenanceProviderEdit } from "./Edit";
import { MaintenanceProviderList } from "./List";

export const maintenanceProviderResource = {
  icon: MaintenanceProviderIcon,
  list: MaintenanceProviderList,
  edit: MaintenanceProviderEdit,
  create: MaintenanceProviderCreate,
  options: { label: "Maintenance Providers" },
  recordRepresentation: "name",
};

export { MaintenanceProviderIcon };
