import DeploymentEndIcon from "@mui/icons-material/AlarmOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DrawIcon from "@mui/icons-material/Draw";
import EmailIcon from "@mui/icons-material/Email";
import ReservationAppEnabledIcon from "@mui/icons-material/EventAvailable";
import DeploymentStartIcon from "@mui/icons-material/RocketLaunch";
import { Grid, Tooltip } from "@mui/material";
import {
  ChipField,
  DateField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  WithRecord,
} from "react-admin";
import { FieldGrid } from "../../../display/FieldGrid";
import { FieldItem } from "../../../display/FieldItem";
import { DealIcon } from "../../deal";
import { ClientIcon } from "../../organization";
import { ProjectShowActions } from "../actions";
import { ProjectContactsField } from "../fields/Contacts";
import { ListingLink } from "../fields/Listing";
import { PipedriveStageField } from "../fields/PipedriveStageField";
import { ProjectDeploymentEndDate } from "../fields/ProjectDeploymentEndDate";
import { ProjectDeploymentStartDate } from "../fields/ProjectDeploymentStartDate";
import { ProjectStageField } from "../fields/Status";
import type { Project } from "../type";
import { ProjectCommunications } from "./tabs/communications";
import { ProjectDeployment } from "./tabs/deployment";
import { InstallationReport } from "./tabs/installation-report";
import { ProjectOverview } from "./tabs/overview";
import { ProjectResidences } from "./tabs/residences";
import { ProjectStagingTest } from "./tabs/staging-test/ProjectStagingTest";

export const ProjectShow = () => {
  return (
    <Show actions={<ProjectShowActions />}>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginTop={2}
        >
          <TextField source="name" variant="h4" />
          <ProjectContactsField />
          <ListingLink />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <ProjectStageField />
          <PipedriveStageField />
          <Tooltip title="Reservation App Enabled">
            <ReservationAppEnabledIcon color="success" />
          </Tooltip>
        </Grid>
      </Grid>

      <FieldGrid>
        <FieldItem label="Calendly utm_source" icon={<CalendarMonthIcon />}>
          <TextField source="calendlyUtmSource" />
        </FieldItem>

        <FieldItem label="Brevo list" icon={<EmailIcon />}>
          <TextField source="brevoListId" />
        </FieldItem>

        <FieldItem label="Signature du contrat" icon={<DrawIcon />}>
          <DateField source="signingDate" />
        </FieldItem>

        <FieldItem label="Début du déploiement" icon={<DeploymentStartIcon />}>
          <ProjectDeploymentStartDate />
        </FieldItem>

        <FieldItem label="Fin du déploiement" icon={<DeploymentEndIcon />}>
          <ProjectDeploymentEndDate />
        </FieldItem>
      </FieldGrid>

      <FieldGrid>
        <FieldItem label="Client" icon={<ClientIcon />}>
          <ReferenceField
            reference="organizations"
            source="organizationId"
            link="show"
          >
            <TextField source="name" variant="body1" />
          </ReferenceField>
        </FieldItem>

        <FieldItem label="Deals" icon={<DealIcon />}>
          <ReferenceArrayField reference="deals" source="deals">
            <SingleFieldList linkType="show" empty={<>-</>}>
              <ChipField source="name" clickable size="small" />
            </SingleFieldList>
          </ReferenceArrayField>
        </FieldItem>
      </FieldGrid>

      <WithRecord<Project>
        render={(project) => (
          <TabbedShowLayout>
            <Tab label="Installation report">
              <InstallationReport />
            </Tab>
            <Tab label="Deployment">
              <ProjectDeployment />
            </Tab>
            <Tab label="Residences">
              <ProjectResidences />
            </Tab>
            <Tab label="Overview">
              <ProjectOverview />
            </Tab>
            <Tab label="Communications">
              <ProjectCommunications />
            </Tab>
            {project?.name &&
              project.name.toLocaleLowerCase().includes("staging") && (
                <Tab label="Staging Test">
                  <ProjectStagingTest />
                </Tab>
              )}
          </TabbedShowLayout>
        )}
      />
    </Show>
  );
};
