import { Grid } from "@mui/material";
import { ConsumptionGraph } from "./widgets/Consumption";
import { SavingsAverageGraph } from "./widgets/SavingsAverage";

export const SavingsV2Dashboard: React.FC<{ filter: any }> = ({ filter }) => {
  return (
    <Grid container spacing={2}>
      <ConsumptionGraph v2 filter={filter ?? {}} />
      <Grid item xs={12} md={6}>
        <SavingsAverageGraph filter={filter ?? {}} />
      </Grid>
    </Grid>
  );
};
