import { FunctionField } from "react-admin";
import type { FunctionFieldProps } from "react-admin";
import type { Except } from "type-fest";
import type { EndUser } from "../type";

export const NameField = (
  props: Except<FunctionFieldProps<EndUser>, "render">
) => {
  return <FunctionField render={serializeEndUserName} {...props} />;
};

export const serializeEndUserName = (endUser: EndUser) =>
  [
    endUser.title1,
    endUser.firstName1,
    endUser.lastName1,
    "&",
    endUser.title2,
    endUser.firstName2,
    endUser.lastName2,
  ]
    .filter((v) => v)
    .join(" ")
    .replace(/&$/, "")
    .trim();
