import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  Pagination,
} from "react-admin";

export const DailySavings = () => {
  return (
    <ReferenceManyField
      reference="device-daily-savings"
      target="device_id"
      sort={{ field: "date", order: "DESC" }}
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        <DateField source="day" label="Date" />
        <TextField source="state.period" label="Period" />
        <TextField source="computed_savings.kwh" label="Savings (kWh)" />
        <TextField source="computed_savings.euros" label="Savings (€)" />
      </Datagrid>
    </ReferenceManyField>
  );
};
