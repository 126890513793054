import EmailIcon from "@mui/icons-material/AlternateEmail";
import ListIcon from "@mui/icons-material/AutoMode";
import SuccessIcon from "@mui/icons-material/Check";
import FailureIcon from "@mui/icons-material/Error";
import LetterIcon from "@mui/icons-material/HistoryEdu";
import PendingIcon from "@mui/icons-material/Pending";
import SmsIcon from "@mui/icons-material/Sms";
import NotPossibleIcon from "@mui/icons-material/Warning";
import type { Color, SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  amber,
  blue,
  brown,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
} from "@mui/material/colors";

export interface Communication {
  id: string;
  context: {
    endUserId?: string;
    residenceId?: string;
    projectId?: string;
    housingId?: string;
    organizationId?: string;
    subscriptionId?: string;
    maintenanceProviderId?: string;
    interventionId?: string;
  };
  topic:
    | ProjectCommunicationTopic
    | OrganizationCommunicationTopic
    | ResidentCommunicationTopic
    | MaintenanceProviderCommunicationTopic;
  medium: CommunicationMedium;
  date: Date;
  externalId?: string;
  status: CommunicationStatus;
  statusDetails?: string;
}

export enum CommunicationStatus {
  NOT_POSSIBLE = "not possible",
  PENDING = "pending",
  SUCCESS = "success",
  FAILURE = "failure",
}

export enum CommunicationMedium {
  EMAIL = "email",
  LETTER = "letter",
  SMS = "sms",
  THIRD_PARTY_LIST = "third party list",
}

export const communicationMediumConfigs: Record<
  CommunicationMedium,
  {
    color: Color;
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  }
> = {
  [CommunicationMedium.EMAIL]: { color: blue, label: "Email", icon: EmailIcon },
  [CommunicationMedium.LETTER]: {
    color: purple,
    label: "Courrier",
    icon: LetterIcon,
  },
  [CommunicationMedium.SMS]: { color: green, label: "SMS", icon: SmsIcon },
  [CommunicationMedium.THIRD_PARTY_LIST]: {
    color: teal,
    label: "Brevo automation",
    icon: ListIcon,
  },
};

export const communicationStatusConfigs: Record<
  CommunicationStatus,
  {
    color: Color;
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  }
> = {
  [CommunicationStatus.SUCCESS]: {
    color: green,
    label: "Succès",
    icon: SuccessIcon,
  },
  [CommunicationStatus.FAILURE]: {
    color: red,
    label: "Échec",
    icon: FailureIcon,
  },
  [CommunicationStatus.PENDING]: {
    color: grey,
    label: "En cours",
    icon: PendingIcon,
  },
  [CommunicationStatus.NOT_POSSIBLE]: {
    color: orange,
    label: "Envoi impossible",
    icon: NotPossibleIcon,
  },
};

export enum ProjectCommunicationTopic {
  SMART_TOGGLE = "SMART_TOGGLE",
  AVOIDED_EMISSIONS = "AVOIDED_EMISSIONS",
  DEPLOYMENT_REVIEW = "DEPLOYMENT_REVIEW",
  DEPLOYMENT_START = "DEPLOYMENT_START",
  LIMESCALE_INFO = "LIMESCALE_INFO",
}

export enum OrganizationCommunicationTopic {
  UPSELL_INCENTIVE = "UPSELL_INCENTIVE",
  MONTHLY_SAVINGS = "MONTHLY_SAVINGS",
}

export enum ResidentCommunicationTopic {
  SMART_TOGGLE = "SMART_TOGGLE",
  FIRST_SAVINGS = "FIRST_SAVINGS",
  INSTALLATION_APPOINTMENT = "INSTALLATION_APPOINTMENT",
  INSTALLATION_APPOINTMENT_REMINDER = "INSTALLATION_APPOINTMENT_REMINDER",

  DEPLOYMENT_LETTER = "DEPLOYMENT_LETTER",
  NPS = "NPS",
  BIMONTHLY_SAVINGS = "BIMONTHLY_SAVINGS",
  DEVICE_PRESENTATION = "DEVICE_PRESENTATION",
}

export enum MaintenanceProviderCommunicationTopic {
  INTERVENTION_CHANGED = "INTERVENTION_CHANGED",
  INTERVENTION_CANCELLED = "INTERVENTION_CANCELLED",
  INTERVENTIONS_REPORT = "INTERVENTIONS_REPORT",
  LAST_MINUTE_INTERVENTION = "LAST_MINUTE_INTERVENTION",
}

export const communicationTopicConfigs: Record<
  | ProjectCommunicationTopic
  | OrganizationCommunicationTopic
  | ResidentCommunicationTopic
  | MaintenanceProviderCommunicationTopic,
  { color: Color; label: string }
> = {
  [ProjectCommunicationTopic.SMART_TOGGLE]: {
    color: red,
    label: "Passage en pilotage",
  },
  [ProjectCommunicationTopic.AVOIDED_EMISSIONS]: {
    color: pink,
    label: "Émissions évitées",
  },
  [ProjectCommunicationTopic.DEPLOYMENT_REVIEW]: {
    color: purple,
    label: "Bilan de déploiement",
  },
  [ProjectCommunicationTopic.DEPLOYMENT_START]: {
    color: deepPurple,
    label: "Démarrage du déploiement",
  },
  [ProjectCommunicationTopic.LIMESCALE_INFO]: {
    color: indigo,
    label: "Calcairisation",
  },
  [OrganizationCommunicationTopic.UPSELL_INCENTIVE]: {
    color: blue,
    label: "Upsell",
  },
  [OrganizationCommunicationTopic.MONTHLY_SAVINGS]: {
    color: lightBlue,
    label: "Économies",
  },
  [ResidentCommunicationTopic.FIRST_SAVINGS]: {
    color: teal,
    label: "Premières économies",
  },
  [ResidentCommunicationTopic.INSTALLATION_APPOINTMENT]: {
    color: green,
    label: "Prise de RDV",
  },
  [ResidentCommunicationTopic.INSTALLATION_APPOINTMENT_REMINDER]: {
    color: green,
    label: "Relance prise de RDV",
  },
  [ResidentCommunicationTopic.DEPLOYMENT_LETTER]: {
    color: lightGreen,
    label: "Prise de RDV",
  },
  [ResidentCommunicationTopic.NPS]: { color: lime, label: "NPS" },
  [ResidentCommunicationTopic.BIMONTHLY_SAVINGS]: {
    color: amber,
    label: "Économies",
  },
  [ResidentCommunicationTopic.DEVICE_PRESENTATION]: {
    color: orange,
    label: "Présentation boitier",
  },
  [MaintenanceProviderCommunicationTopic.INTERVENTION_CHANGED]: {
    color: deepOrange,
    label: "RDV modifié",
  },
  [MaintenanceProviderCommunicationTopic.INTERVENTION_CANCELLED]: {
    color: deepOrange,
    label: "RDV annulé",
  },
  [MaintenanceProviderCommunicationTopic.INTERVENTIONS_REPORT]: {
    color: brown,
    label: "Rapport d'interventions",
  },
  [MaintenanceProviderCommunicationTopic.LAST_MINUTE_INTERVENTION]: {
    color: deepOrange,
    label: "Intervention de dernière minute",
  },
};
