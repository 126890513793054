import { Paper } from "@mui/material";
import { useToggleFilter } from "../../../filters/useToggleFilter";
import { useIncidentsState } from "../../../providers/data-hooks/useIncidentsState";
import { codeToLabel } from "../../../resources/alerts/fields/AlertCodeField";
import { CountEnumChart } from "./CountEnumChart";

export const IncidentsType = ({
  filter,
}: {
  filter?: { projectId: string } | { dealId: string };
}) => {
  const counts = useIncidentsState("type", filter);
  const [filteredValue, toggleFilter] = useToggleFilter("type");

  return (
    <Paper elevation={1}>
      <CountEnumChart
        title="Type d'incidents"
        onClick={(point) => toggleFilter(point.custom.type)}
        data={
          counts &&
          Object.entries(counts).map(([type, count]) => ({
            name: codeToLabel[type]?.label,
            y: count,
            sliced: filteredValue === type,
            custom: {
              type,
            },
          }))
        }
      />
    </Paper>
  );
};
