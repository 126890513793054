import { entries, omit, pick } from "lodash";
import { useQuery } from "react-query";
import { useQueryParam, JsonParam } from "use-query-params";
import type { IncidentStatus } from "../../resources/incidents/types";
import { axiosClient } from "../data";

export const useIncidentsState = (
  source: "type" | "status",
  filter?: { projectId: string } | { dealId: string }
) => {
  const [allFilters] = useQueryParam("filters", JsonParam);
  const filters = entries(
    omit(pick(allFilters, ["type", "status"]), source)
  ) as ["type" | "status", string][];

  const { data } = useQuery(["incidents/state", filter], () =>
    axiosClient
      .get<
        {
          status: IncidentStatus;
          type: string;
          count: number;
        }[]
      >("/incidents/state", {
        params: { filter: JSON.stringify(filter) },
      })
      .then((res) => res.data)
  );

  return data
    ?.filter((row) => filters.every(([col, val]) => row[col] === val))
    .reduce((acc, row) => {
      acc[row[source]] = (acc[row[source]] || 0) + row.count;
      return acc;
    }, {} as Record<string, number>);
};
