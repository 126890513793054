import { useQuery } from "react-query";
import type { InterventionStatus } from "../../resources/intervention/types";
import { axiosClient } from "../data";

export const useMaintenancesState = (
  filter?: { projectId: string } | { dealId: string }
) => {
  const { data } = useQuery(["maintenances/state", filter], () =>
    axiosClient
      .get<
        {
          status: InterventionStatus;
          count: number;
        }[]
      >("/maintenances/state", {
        params: { filter: JSON.stringify(filter) },
      })
      .then((res) => res.data)
  );
  return data;
};
