import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import dayjs from "dayjs";
import type Highcharts from "highcharts";
import { groupBy, min, range, sumBy } from "lodash";
import { useQuery } from "react-query";
import { dataProvider } from "../../../providers/data";
import { Chart } from "../../Chart";
import { useYearlyNormalized } from "../YearlyNormalizationSelect";
import { granularities, useGranularity } from "./useGranularity";

export const ConsumptionBar: React.FC<{ filter: any }> = ({ filter }) => {
  const [yearlyNormalized] = useYearlyNormalized();
  const adaptedFilters = {
    ...filter,
    observation: false,
  };
  const { data, isFetching } = useQuery({
    queryFn: () =>
      dataProvider.getDevicesConsumption(adaptedFilters, yearlyNormalized),
    queryKey: [`devices/consumption`, adaptedFilters, yearlyNormalized],
    enabled: !!filter,
  });
  const [granularity, setGranularity] = useGranularity();

  const minTimestamp = min(
    (data?.data.consumptionProgression || []).map((i) => i.timestamp)
  );
  const maxTimestamp = dayjs().endOf(granularity.value as any);

  const categories = range(
    dayjs(maxTimestamp).diff(minTimestamp, granularity.value as any)
  )
    .slice(-granularity.history)
    .map((i) =>
      granularity.format(
        dayjs(minTimestamp).add(i + 1, granularity.value as any)
      )
    );

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: `Consommation ${granularity.label} du parc`,
    },
    subtitle: {
      text: `boîtiers v2 en pilotage uniquement`,
    },
    xAxis: {
      categories,
      gridLineWidth: 1,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
    },
    series: [
      { name: "Consommation Mesurée", selector: "measuredConsumptionInKWh" },
      {
        name: "Consommation Estimée Sans Elax",
        selector: "estimatedNonPilotedConsumptionInKWh",
      },
    ].map(({ name, selector }) => {
      const values = groupBy(data?.data.consumptionProgression, (datum) =>
        granularity.format(dayjs(datum.timestamp))
      );

      return {
        name,
        type: "column",
        data: categories.map((category) => ({
          y: Math.round(sumBy(values[category] ?? [], selector)),
        })),
      };
    }),
  };

  return (
    <Paper elevation={1} sx={{ position: "relative" }}>
      <FormControl
        sx={{ position: "absolute", right: 40, top: 5, zIndex: 10 }}
        size="small"
        className="no-print"
      >
        <InputLabel>Granularité</InputLabel>
        <Select
          label="Granularité"
          onChange={(event) => {
            setGranularity(
              granularities.find(
                (g) => g.value === (event.target.value as string)
              )!
            );
          }}
          value={granularity.value}
        >
          {granularities.map((g) => (
            <MenuItem key={g.value} value={g.value}>
              {g.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Chart options={options} isLoading={isFetching} />
    </Paper>
  );
};
