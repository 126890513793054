import OrganizationIcon from "@mui/icons-material/RocketLaunch";
import { OrganizationEdit } from "./Edit";
import { OrganizationList } from "./List";
import { OrganizationShow } from "./show";

export const organizationResource = {
  icon: OrganizationIcon,
  list: OrganizationList,
  edit: OrganizationEdit,
  show: OrganizationShow,
  recordRepresentation: "name",
};

export { OrganizationIcon as ClientIcon };
