import { Grid } from "@mui/material";
import { ReferenceManyField } from "react-admin";
import { useQueryParam, JsonParam } from "use-query-params";
import DeploymentSummary from "../../intervention/reporting/DeploymentSummary";
import { RefusalReasonsStats } from "../../intervention/reporting/RefusalReasons";
import { SourcingStatusStats } from "../../intervention/reporting/SourcingStatus";
import { SourcingTypeStats } from "../../intervention/reporting/SourcingType";
import { InterventionType } from "../../intervention/types";

export const DealInstallationReportTab = () => {
  const [filters] = useQueryParam("filters", JsonParam);

  return (
    <Grid container>
      <Grid item xs={12} marginBottom={3}>
        <DeploymentSummary entity="deal" />
      </Grid>
      <ReferenceManyField
        reference="interventions"
        target="dealId"
        page={1}
        perPage={10000}
        filter={{ type: InterventionType.INSTALLATION, ...filters }}
      >
        <Grid item md={4} xs={12}>
          <SourcingStatusStats entity="deal" />
        </Grid>
        <Grid item md={4} xs={12}>
          <SourcingTypeStats entity="deal" />
        </Grid>
        <Grid item md={4} xs={12}>
          <RefusalReasonsStats entity="deal" />
        </Grid>
      </ReferenceManyField>
    </Grid>
  );
};
