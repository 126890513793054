export interface ConfigFrame {
  id: string;
  device_id: string;
  ts: string;
  timestamp: number;
  set_point: number;
  hysteresis: number;
  set_point2?: number;
  hysteresis2?: number;
  flags?: Record<string, boolean>;
  origin: string;
}

export const serializeConfigFrame = (frame: ConfigFrame) => {
  const flags = Object.entries(frame.flags ?? {})
    .filter(([, value]) => value)
    .map(([flag]) => flag);
  if (flags.length > 0) {
    return flags.join(",") + ` (origin=${frame.origin})`;
  }

  const instruction1 = `${frame.set_point} ±${(frame.hysteresis / 10).toFixed(
    1
  )}°C`;
  const instruction2 =
    frame.set_point2 !== undefined
      ? `${frame.set_point2} ±${(frame.hysteresis / 10).toFixed(1)}°C`
      : "";

  return `${instruction1}${instruction2 ? ` ${instruction2}` : ""}`;
};
