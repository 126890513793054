import IncidentIcon from "@mui/icons-material/Troubleshoot";
import { IncidentEdit } from "./Edit";
import { IncidentList } from "./list/List";

export const incidentResource = {
  list: IncidentList,
  edit: IncidentEdit,
  icon: IncidentIcon,
};

export { IncidentIcon };
