import Icon from "@mui/icons-material/Settings";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRecordContext } from "react-admin";
import CardWithIcon from "../../../layout/CardWithIcon";
import { Mode } from "../../device-config/types";
import { useGroups, useLastDeviceConfig, useOwner } from "./DeviceConfig";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const SettingsField = () => {
  const record = useRecordContext();
  const lastDeviceConfig = useLastDeviceConfig();
  const groups = useGroups();
  const owner = useOwner();

  let targetPilotDate: Dayjs | undefined = undefined;
  if (lastDeviceConfig?.mode === Mode.OBSERVATION) {
    targetPilotDate = dayjs(lastDeviceConfig.createdAt).add(60, "days");
  }

  return (
    <CardWithIcon
      icon={Icon}
      color="#ffc107"
      data={
        record
          ? [
              {
                label: "Mode",
                value: lastDeviceConfig
                  ? `${lastDeviceConfig.mode} (for ${dayjs
                      .duration(dayjs().diff(lastDeviceConfig.createdAt))
                      .humanize()})`
                  : "",
              },
              {
                label: "Estimated pilot date",
                value: targetPilotDate?.format("YYYY-MM-DD") ?? "",
                display: Boolean(targetPilotDate),
              },
              {
                label: "Current Config",
                value: record.latestConfigAckFrame
                  ? `${record.latestConfigAckFrame.set_point}°C ±${(
                      record.latestConfigAckFrame.hysteresis / 10
                    ).toFixed(1)}°C`
                  : "",
              },
              {
                label: "Groups",
                value: groups.join(", "),
                display: Boolean(groups?.length),
              },
              {
                label: "Owner",
                value: owner?.email ?? "",
                display: Boolean(owner),
              },
            ]
          : []
      }
    />
  );
};
