import { Box, Divider, Grid } from "@mui/material";
import {
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  WithRecord,
  useRecordContext,
} from "react-admin";
import { useQuery } from "react-query";
import { Chart } from "../../layout/Chart";
import { ConsumptionGraph } from "../../layout/dashboard/widgets/Consumption";
import { ConsumptionBar } from "../../layout/dashboard/widgets/ConsumptionBar";
import { ContractType } from "../../layout/dashboard/widgets/ContractType";
import { DevicesState } from "../../layout/dashboard/widgets/DevicesState";
import { HealthRisk } from "../../layout/dashboard/widgets/HealthRisk";
import { HeatersState } from "../../layout/dashboard/widgets/HeaterState";
import { SavingsState } from "../../layout/dashboard/widgets/SavingsState";
import { dataProvider } from "../../providers/data";
import { DevicesDatagrid } from "../devices/List";
import { GroupActions } from "./actions";
import { RemoveFromGroupButton } from "./actions/RemoveFromGroup";
import { GroupIcon } from ".";

export const GroupShow = () => {
  return (
    <Show actions={<GroupActions />}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          margin={2}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GroupIcon fontSize="large" sx={{ marginRight: 1 }} />
            <TextField source="groupName" variant="h4" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField label="groupDescription" source="name" />
        </Grid>
      </Grid>
      <TabbedShowLayout>
        <Tab label="Devices">
          <Grid container spacing={2}>
            <WithRecord
              render={(record) => (
                <Grid item xs={12}>
                  <ReferenceManyField
                    reference="devices"
                    target="group"
                    label={false}
                    perPage={1000}
                    fullWidth
                  >
                    <DevicesDatagrid bulkActionButtons={false}>
                      <RemoveFromGroupButton groupId={record.id as string} />
                    </DevicesDatagrid>
                  </ReferenceManyField>
                </Grid>
              )}
            />
          </Grid>
        </Tab>
        <Tab label="Consommation">
          <GroupDashboard />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const GroupDashboard = () => {
  const group = useRecordContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RowConsumptionGraph />
      </Grid>
      <ConsumptionGraph filter={group && { group: group.id }} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ConsumptionBar filter={group && { group: group.id }} />
      </Grid>
      <HealthRisk filter={group && { group: group.id }} />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <ContractType filter={group && { group: group.id }} />
      </Grid>
      <Grid item xs={6}>
        <SavingsState filter={group && { group: group.id }} />
      </Grid>
      <Grid item xs={6}>
        <HeatersState filter={group && { group: group.id }} />
      </Grid>
      <Grid item xs={6}>
        <DevicesState filter={group && { group: group.id }} />
      </Grid>
    </Grid>
  );
};

export const RowConsumptionGraph: React.FC = () => {
  const group = useRecordContext();
  const { data } = useQuery({
    queryFn: () => dataProvider.getDevicesRawConsumption({ group: group.id }),
    queryKey: [`devices/raw-consumption`, { group: group.id }],
    enabled: !!group,
  });

  return (
    <>
      <Grid item xs={12}>
        <Chart
          isLoading={!data}
          options={{
            ...chartOptions,
            series: data && [
              {
                name: "Consommation mesurée",
                type: "spline",
                color: "#2196f3",
                data: data.consumption.map((point) => ({
                  ...point,
                })),
                tooltip: {
                  headerFormat: "<b>{point.key}</b>",
                  xDateFormat: "%A %d %B",
                  valueDecimals: 3,
                  pointFormat: `<br />{point.y}kWh`,
                },
              },
            ],
          }}
        />
      </Grid>
    </>
  );
};

const chartOptions: Highcharts.Options = {
  chart: {
    type: "line",
  },
  title: {
    text: "Consommation",
  },
  plotOptions: {
    spline: {
      lineWidth: 3,
      states: {
        hover: {
          lineWidth: 5,
        },
      },
      marker: {
        enabled: false,
      },
    },
  },
  xAxis: {
    crosshair: true,
    type: "datetime",
    tickPixelInterval: 150,
  },
  yAxis: {
    title: {
      text: "Consommation quotidienne",
    },
    labels: {
      formatter: function () {
        return this.value + "kWh";
      },
    },
  },
};
