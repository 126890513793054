import ListItemIcon from "@mui/icons-material/Summarize";
import { ListItemList } from "./List";

export const listItemResource = {
  list: ListItemList,
  icon: ListItemIcon,
  hasShow: false,
  hasCreate: false,
  hasEdit: false,
};

export { ListItemIcon };
