import pLimit from "p-limit";
import { useCallback, useState } from "react";
import { useGetList, useRefresh } from "react-admin";
import { useBulkActionFilter } from "../../../../actions/useBulkActionFilter";
import { dataProvider } from "../../../../providers/data";
import type { Device } from "../../types";

const limit = pLimit(5);

export const useEditDeviceConfig = (notes: string) => {
  const [results, setResults] = useState<
    Record<string, { status: string; message?: string }>
  >({});
  const [submitted, setSubmitted] = useState(false);

  const filter = useBulkActionFilter();
  const { data: devices } = useGetList<Device>("devices", {
    filter,
    pagination: { perPage: 10000, page: 1 },
    sort: { field: "id", order: "ASC" },
  });
  const refresh = useRefresh();

  const setResult = useCallback(
    (deviceId: string, status: string, message?: string) => {
      setResults((results) => ({
        ...results,
        [deviceId]: { status, message },
      }));
    },
    []
  );

  const editDevice = useCallback(
    async (device: Device, data: any) => {
      try {
        if (device.custom_pilot) {
          setResult(device.id, `skipped (custom pilot enabled)`);
          return;
        }
        const config = device.HoraireHCHP?.length ? data.hc : data.base;

        await dataProvider.create("device-configs", {
          data: { ...config, deviceId: device.id, notes },
        });
        setResult(device.id, "OK");
        refresh();
      } catch (error: any) {
        setResult(device.id, "error", error.message);
      }
    },
    [setResult, notes, refresh]
  );

  const edit = useCallback(
    (data: any) => {
      setSubmitted(true);
      setResults(
        devices?.reduce(
          (s, device) => ({ ...s, [device.id]: { status: "pending" } }),
          {} as any
        )
      );

      for (const device of devices || []) {
        limit(() => editDevice(device, data));
      }
    },
    [devices, editDevice]
  );

  return {
    edit,
    results,
    submitted,
    devices,
    editDevice,
    isPending: Object.values(results).some(
      (result) => result.status === "pending"
    ),
  };
};
